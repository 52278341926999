import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img id="logo" src={logo} />
        <p>
          <span style={{ color: '#ff7200', fontWeight: 300 }}>Ravel</span> <span style={{ fontWeight: 600 }}>Studios</span>
        </p>
      </header>
    </div>
  );
}

export default App;
